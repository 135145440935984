import React, { Component, useState }  from 'react';
import { instanceOf } from 'prop-types';
import {
  ChakraProvider,
  Box,
  Heading,
  Button,
  Text,
//  Link,
  VStack,
  HStack,
//  Code,
  Grid,
  GridItem,
  theme,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Checkbox,
  useColorModeValue,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
//import { Logo } from './Logo';
import { LockIcon, UnlockIcon } from '@chakra-ui/icons';
import { PinInput, PinInputField } from '@chakra-ui/react';
import Confetti from 'react-dom-confetti';
import ReactGA from "react-ga4";
import { CookiesProvider, useCookies, withCookies, Cookies } from "react-cookie";
import { v4 as uuidv4 } from "uuid";
import Countdown from 'react-countdown';
import {CopyToClipboard} from 'react-copy-to-clipboard';

function Hints(props) {
  const [hints,setHints] = useState(createHints(props.digits));
  const [gameOver,setGameOver] = useState(props.gameOver);

  function createHints(digits) {
    //console.log("Next digits are: " + digits.toString());
    let hints = [];
    
    // One number is correct and in the right place
    // Pick a random position
    const clue1rightright  = Math.floor(Math.random() * 3);
    
    // Pick two random extra digits
    const clue1wrong1 = Math.floor(Math.random() * 6+3);
    let clue1wrong2 = Math.floor(Math.random() * 6+3);
    while (clue1wrong1 === clue1wrong2) {
      clue1wrong2 = Math.floor(Math.random() * 6+3);
    }
    
    hints.push([])
    if (clue1rightright === 0) {
      hints[0].push(digits[clue1rightright]);
      hints[0].push(digits[clue1wrong1]);
      hints[0].push(digits[clue1wrong2]);
    } else if (clue1rightright === 1) {
      hints[0].push(digits[clue1wrong1]);
      hints[0].push(digits[clue1rightright]);
      hints[0].push(digits[clue1wrong2]);
    } else {
      hints[0].push(digits[clue1wrong1]);
      hints[0].push(digits[clue1wrong2]);
      hints[0].push(digits[clue1rightright]);
    }
    
    // One number is correct but in the wrong place
    // Pick a different digit from step 1 plus one wrong digit from step 1 that goes in the same place
    let clue2rightwrong = Math.floor(Math.random() * 3);
    while (clue2rightwrong === clue1rightright) {
      clue2rightwrong = Math.floor(Math.random() * 3);
    }
    
    let clue2wrong = Math.floor(Math.random() * 6+3);
    while (clue2wrong === clue1wrong1 || clue2wrong === clue1wrong2) {
      clue2wrong = Math.floor(Math.random() * 6+3);
    }

    hints.push([])
    if (clue1rightright === 0) {
      if (clue2rightwrong === 1) {
        hints[1].push(digits[clue2wrong]);
        hints[1].push(digits[clue1wrong1]);
        hints[1].push(digits[clue2rightwrong]);
      } else {
        hints[1].push(digits[clue2wrong]);
        hints[1].push(digits[clue2rightwrong]);
        hints[1].push(digits[clue1wrong2]);
      }
    } else if (clue1rightright === 1) {
      if (clue2rightwrong === 0) {
        hints[1].push(digits[clue1wrong1]);
        hints[1].push(digits[clue2wrong]);
        hints[1].push(digits[clue2rightwrong]);
      } else {
        hints[1].push(digits[clue2rightwrong]);
        hints[1].push(digits[clue2wrong]);
        hints[1].push(digits[clue1wrong2]);
      } 
    } else {
      if (clue2rightwrong === 0) {
        hints[1].push(digits[clue1wrong1]);
        hints[1].push(digits[clue2rightwrong]);
        hints[1].push(digits[clue2wrong]);
      } else {
        hints[1].push(digits[clue2rightwrong]);
        hints[1].push(digits[clue1wrong2]);
        hints[1].push(digits[clue2wrong]);
      } 
    }
    
    // Two numbers are correct but in the wrong place
    // The numbers you didn't pick in step 1 plus a wrong digit you haven't used yet
    let clue3rightwrong =  Math.floor(Math.random() * 3);
    while (clue3rightwrong === clue1rightright || clue3rightwrong === clue2rightwrong) {
      clue3rightwrong =  Math.floor(Math.random() * 3)
    }
    
    let clue3wrong = Math.floor(Math.random() * 6+3);
    while (clue3wrong === clue1wrong1 || clue3wrong === clue1wrong2 || clue3wrong === clue2wrong) {
      clue3wrong = Math.floor(Math.random() * 6+3);
    }
    
    hints.push([])
    if (clue1rightright === 0) {
      if (clue2rightwrong === 1) {
        hints[2].push(digits[clue2rightwrong]);
        hints[2].push(digits[clue3rightwrong]);
        hints[2].push(digits[clue3wrong]);
      } else {
        hints[2].push(digits[clue2rightwrong]);
        hints[2].push(digits[clue3wrong]);
        hints[2].push(digits[clue3rightwrong]);
      }
    } else if (clue1rightright === 1) {
      if (clue2rightwrong === 0) {
        hints[2].push(digits[clue3rightwrong]);
        hints[2].push(digits[clue2rightwrong]);
        hints[2].push(digits[clue3wrong]);
      } else {
        hints[2].push(digits[clue3wrong]);
        hints[2].push(digits[clue2rightwrong]);
        hints[2].push(digits[clue3rightwrong]);
      } 
    } else {
      if (clue2rightwrong === 0) {
        hints[2].push(digits[clue3rightwrong]);
        hints[2].push(digits[clue3wrong]);
        hints[2].push(digits[clue2rightwrong]);
      } else {
        hints[2].push(digits[clue3wrong]);
        hints[2].push(digits[clue3rightwrong]);
        hints[2].push(digits[clue2rightwrong]);
      } 
    }
    
    // Nothing is correct
    // two new random wrong digits plus one previously seen
    let clue4wrong1 = Math.floor(Math.random() * 6+3);
    while (clue4wrong1 === clue1wrong1 || clue4wrong1 === clue1wrong2 || clue4wrong1 === clue2wrong || clue4wrong1 === clue3wrong) {
      clue4wrong1 = Math.floor(Math.random() * 6+3);
    }
    let clue4wrong2 = Math.floor(Math.random() * 6+3);
    while (clue4wrong2 === clue1wrong1 || clue4wrong2 === clue1wrong2 || clue4wrong2 === clue2wrong || clue4wrong2 === clue3wrong || clue4wrong2 === clue4wrong1) {
      clue4wrong2 = Math.floor(Math.random() * 6+3);
    }
    let clue4wrong3 = Math.floor(Math.random() * 6+3);
    while (clue4wrong3 === clue4wrong1 || clue4wrong3 === clue4wrong2) {
      clue4wrong3 = Math.floor(Math.random() * 6+3);
    }
    
    hints.push([]);
    hints[3].push(digits[clue4wrong1]);
    hints[3].push(digits[clue4wrong2]);
    hints[3].push(digits[clue4wrong3]);
    
    // One number is correct but in the wrong place
    // the digit from step 1 but in the wrong place plus the wrong digit from step 3 in the same place plus any digit from step 4
    
    let clue5wrong = clue3wrong;
    while (clue5wrong === clue3wrong) {
      clue5wrong = Math.floor(Math.random() * 3);
    if (clue5wrong === 0) {
      clue5wrong = clue4wrong1;
    } else if (clue5wrong === 1) {
      clue5wrong = clue4wrong2;
    } else {
      clue5wrong = clue4wrong3;
    }
    }
    
    hints.push([]);
    if (clue1rightright === 0) {
      if (clue2rightwrong === 1) {
        hints[4].push(digits[clue3wrong]);
        hints[4].push(digits[clue1rightright]);
        hints[4].push(digits[clue5wrong]);
      } else {
        hints[4].push(digits[clue3wrong]);
        hints[4].push(digits[clue5wrong]);
        hints[4].push(digits[clue1rightright]);
      }
    } else if (clue1rightright === 1) {
      if (clue2rightwrong === 0) {
        hints[4].push(digits[clue1rightright]);
        hints[4].push(digits[clue3wrong]);
        hints[4].push(digits[clue5wrong]);
      } else {
        hints[4].push(digits[clue5wrong]);
        hints[4].push(digits[clue3wrong]);
        hints[4].push(digits[clue1rightright]);
      }
    } else {
      if (clue2rightwrong === 0) {
        hints[4].push(digits[clue1rightright]);
        hints[4].push(digits[clue5wrong]);
        hints[4].push(digits[clue3wrong]);
      } else {
        hints[4].push(digits[clue5wrong]);
        hints[4].push(digits[clue1rightright]);
        hints[4].push(digits[clue3wrong]);
      }
    }
    hints[0].push("One number is correct and in the right place.");
    hints[1].push("One number is correct but in the wrong place.");
    hints[2].push("Two numbers are correct but in the wrong place.");
    hints[3].push("Nothing is correct.");
    hints[4].push("One number is correct but in the wrong place.");
    
    hints = hints.sort(() => Math.random() - 0.5);
    return hints;
  };

  return(
      <Grid hidden={gameOver}
      templateAreas={`"header header" 
      "hint1digits hint1text"
      "hint2digits hint2text"
      "hint3digits hint3text"
      "hint4digits hint4text"
      "hint5digits hint5text"`}
      gridTemplateRows={'1.5fr 1fr 1fr 1fr 1fr 1fr'}
      gridTemplateColumns={'1fr 2.25fr'}
      gap='1'>
        <GridItem area={'header'}><Heading>Hints</Heading></GridItem>
        <GridItem area={'hint1digits'}>
          <HStack>
          <Hint name="hint1digit1" digitValue={hints[0][0]} />
          <Hint name="hint1digit2" digitValue={hints[0][1]} />
          <Hint name="hint1digit3" digitValue={hints[0][2]} />
          </HStack>
        </GridItem>
        <GridItem area={'hint1text'}><Text textAlign="left">{hints[0][3]}</Text></GridItem>
        <GridItem area={'hint2digits'}>
          <HStack>
          <Hint name="hint2digit1" digitValue={hints[1][0]} />
          <Hint name="hint2digit2" digitValue={hints[1][1]} />
          <Hint name="hint2digit3" digitValue={hints[1][2]} />
          </HStack>
        </GridItem>
        <GridItem area={'hint2text'}><Text textAlign="left">{hints[1][3]}</Text></GridItem>
        <GridItem area={'hint3digits'}>
          <HStack>
          <Hint name="hint3digit1" digitValue={hints[2][0]} />
          <Hint name="hint3digit2" digitValue={hints[2][1]} />
          <Hint name="hint3digit3" digitValue={hints[2][2]} />
          </HStack>
        </GridItem>
        <GridItem area={'hint3text'}><Text textAlign="left">{hints[2][3]}</Text></GridItem>
        <GridItem area={'hint4digits'}>
          <HStack>
          <Hint name="hint4digit1" digitValue={hints[3][0]} />
          <Hint name="hint4digit2" digitValue={hints[3][1]} />
          <Hint name="hint4digit3" digitValue={hints[3][2]} />
          </HStack>
        </GridItem>
        <GridItem area={'hint4text'}><Text textAlign="left">{hints[3][3]}</Text></GridItem>
        <GridItem area={'hint5digits'}>
          <HStack>
          <Hint name="hint5digit1" digitValue={hints[4][0]} />
          <Hint name="hint5digit2" digitValue={hints[4][1]} />
          <Hint name="hint5digit3" digitValue={hints[4][2]} />
          </HStack>
        </GridItem>
        <GridItem area={'hint5text'}><Text textAlign="left">{hints[4][3]}</Text></GridItem>
      </Grid>
  );
};

function Hint(props) {
  const [digitColor,setDigitColor] = useState("gray");
  const [digitValue,setDigitValue] = useState(props.digitValue);

  return(
    <Button colorScheme={digitColor} onClick={handleClick}>{digitValue}</Button>
  );

  function handleClick() {
    if(digitColor === "gray") {
      setDigitColor("red");
    } else if(digitColor === "red") {
      setDigitColor("yellow");
    } else if(digitColor === "yellow") {
      setDigitColor("green");
    } else {
      setDigitColor("gray");
    }
  };
};

function Padlocks(props) {
  const [lockStatus,setLockStatus] = useState(props.lockStatus);
    return (
    <VStack spacing={3}>
    <HStack>
    <Padlock lockStatus={lockStatus[0]} />
    <Padlock lockStatus={lockStatus[1]} />
    <Padlock lockStatus={lockStatus[2]} />
    </HStack>
    <HStack>
    <Padlock lockStatus={lockStatus[3]} />
    <Padlock lockStatus={lockStatus[4]} />
    <Padlock lockStatus={lockStatus[5]} />
    </HStack>
    <HStack>
    <Padlock lockStatus={lockStatus[6]} />
    <Padlock lockStatus={lockStatus[7]} />
    <Padlock lockStatus={lockStatus[8]} />
    </HStack>
    </VStack>
  );
};

function Padlock(props) {
  const [lockStatus,setLockStatus] = useState(props.lockStatus);

  if (lockStatus === "indeterminate") {
    return(
      <Box p={2} border="1px" borderRadius={6} borderColor="black" _dark={{ borderColor: "white" }}>
      <LockIcon boxSize={20} />
      </Box>
    );  
  } else if (lockStatus === "locked") {
    return(
      <Box p={2} border="1px" borderRadius={6} borderColor="black" _dark={{ borderColor: "white" }}>
      <LockIcon boxSize={20} color="red.500" _dark={{ color: "red.200" }} />
      </Box>
    );  
  } else {
    return(
      <Box p={2} border="1px" borderRadius={6} borderColor="black" _dark={{ borderColor: "white" }}>
      <UnlockIcon boxSize={20} color="green.500" _dark={{ color: "green.200" }} />
      </Box>
    );

  }
};

function TimeTillReset(props) {
  const [gameOver,setGameOver] = useState(props.gameOver);
  let myDate = new Date();
  myDate.setHours(0,0,0,0);
  myDate.setDate(myDate.getDate()+1);
  //console.log("Reset time: " + myDate.toString());
  return (
    <Text hidden={!gameOver}>The locks will reset in <Countdown date={myDate} /></Text>
  )
};

function ResultsHTML(props) {
  const [lockStatus,setLockStatus] = useState(props.lockStatus);
  const [streak,setStreak] = useState(props.streak);
  let lockEmojis = [];
  for (let i = 0; i < lockStatus.length; i++) {
    if (lockStatus[i] === "unlocked") {
      lockEmojis.push("🔓");
    } else {
      lockEmojis.push("🔒");
    }
  }

  return (
    <Box>
      <VStack>
      <HStack>
          <Text fontSize="5xl">{lockEmojis[0]}</Text>
          <Text fontSize="5xl">{lockEmojis[1]}</Text>
          <Text fontSize="5xl">{lockEmojis[2]}</Text>
        </HStack>
        <HStack>
          <Text fontSize="5xl">{lockEmojis[3]}</Text>
          <Text fontSize="5xl">{lockEmojis[4]}</Text>
          <Text fontSize="5xl">{lockEmojis[5]}</Text>
        </HStack>
        <HStack>
          <Text fontSize="5xl">{lockEmojis[6]}</Text>
          <Text fontSize="5xl">{lockEmojis[7]}</Text>
          <Text fontSize="5xl">{lockEmojis[8]}</Text>
        </HStack>
        <Text>Streak: {streak}</Text> 
      </VStack>
    </Box>
  );

};

function ResultsButton(props) {
  const [lockStatus,setLockStatus] = useState(props.lockStatus);
  const [streak,setStreak] = useState(props.streak);
  const [confirmCopy, setConfirmCopy] = useState(false);
  const onClose = () => setConfirmCopy(false)
  const cancelRef = React.useRef()
  const today = new Date().toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) 

  let lockEmojis = [];
  for (let i = 0; i < lockStatus.length; i++) {
    if (lockStatus[i] === "unlocked") {
      lockEmojis.push("🔓");
    } else {
      lockEmojis.push("🔒");
    }
  }

  let copyText = 
  "unlockr.win results for " + today + "\r\n"
  + lockEmojis[0]
  + lockEmojis[1]
  + lockEmojis[2] + "\r\n"
  + lockEmojis[3]
  + lockEmojis[4]
  + lockEmojis[5] + "\r\n"
  + lockEmojis[6]
  + lockEmojis[7]
  + lockEmojis[8]
  + "\r\nStreak: " + streak + "\r\nhttps://unlockr.win"; 

  return (
    <>
      <CopyToClipboard text={copyText} onCopy={() => setConfirmCopy(true)} >
        <Button>Share Results</Button>
      </CopyToClipboard>
      <AlertDialog isOpen={confirmCopy} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">Success!</AlertDialogHeader>
            <AlertDialogBody>Results copied to clipboard!</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>Thanks!</Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );


};

function App() {
  const [cookies, setCookie, removeCookie ] = useCookies(['dontShowHelp','streak','guesses','gameDate','lockStatus','gameOver']);
  const [gameOver, setGameOver] = useState(readGameOverCookie());
  const [guesses,setGuesses] = useState(readGuessesCookie());
  const [streak,setStreak] = useState(readStreakCookie());
  const [gameDate,setGameDate] = useState(readGameDateCookie());
  const [isOpenHelpModal,setIsOpenHelpModal] = useState(!readDontShowHelpCookie());
  const [dontShowHelpOnStart,setDontShowHelpOnStart] = useState(readDontShowHelpCookie());
  const [digits, setDigits] = useState(nextLock());
  const [guess,setGuess] = useState(0);
  const [guessKey,setGuessKey] = useState(uuidv4());
  const [lockStatus,setLockStatus] = useState(readLockStatusCookie());
  const [lockNumber,setLockNumber] = useState(0);
  const [confettiActive,setConfettiActive] = useState(false);
  const [isOpenSolvedModal,setIsOpenSolvedModal] = useState(false);
  const confettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
  };

  ReactGA.initialize("G-4VNNZZBLM8");

  function readGameOverCookie() {
    if (cookies.gameOver === "true") {
      return true;
    } else {
      return false;
    }
  };

  function readGameDateCookie() {
    let d = new Date();
    d.setHours(0,0,0,0);
    if (cookies.gameDate) { 
      // if the current game date is yesterday, reset the game...
      const e = Date.parse(cookies.gameDate);
      if (d > e) {
        setCookie('gameDate', d, { path: '/' });
        setCookie('guesses', 10, { path: '/' });
        removeCookie('lockStatus', { path: '/' });
        removeCookie('gameOver', {path: '/'});
        window.location.reload();
      } else {
        return d;
      }
    } else {
      setCookie('gameDate', d, { path: '/' });
      return d;
    }
  };

  function readLockStatusCookie() {
    if (cookies.lockStatus) {
      const myStatus = cookies.lockStatus;
      return myStatus;
    } else {
      const myStatus = ['indeterminate','indeterminate','indeterminate','indeterminate','indeterminate','indeterminate','indeterminate','indeterminate','indeterminate'];
      setCookie('lockStatus', myStatus, { path: '/' });
      return myStatus;
    }
  };

  function nextLock() {
    const digits = [];
    while (digits.length < 9) {
      if (digits.length === 0) {
        digits.push(Math.floor(Math.random() * 10));
      }
      let test = Math.floor(Math.random() * 10);
      let count = 0;
      while (count < digits.length) {
        if (test === digits[count]) {
          test = Math.floor(Math.random() * 10);
          count = 0;
        } else {
          count++;
        }
      } 
      digits.push(test);
    }
    return digits;
  };

  function toggleShowHelpModal(event) {
    if (event.target.checked) {
      setCookie('dontShowHelp', true, { path: '/' });
    } else {
      setCookie('dontShowHelp', false, { path: '/' });
    }
    setDontShowHelpOnStart(event.target.checked);
  };

  function checkSolution() {
    if (guess.length === 3) {
      let gameOver = false;
      if (guesses === 1) {
        gameOver = true;
      }
      setCookie("guesses", guesses-1, { path: '/' });
      setGuesses(guesses-1);
      const solution = digits[0].toString() + digits[1].toString() + digits[2].toString();
      if (guess === solution) {
        console.log("Woo-hoo! The user solved the puzzle!");
        let newLockStatus = [];
        for (let i = 0; i < lockStatus.length; i++) {
          if (i === lockNumber) {
            newLockStatus.push("unlocked");
          } else {
            if (gameOver === true && i > lockNumber) {
              newLockStatus.push("locked");
            } else {
              newLockStatus.push(lockStatus[i]);
            }
          }
        }
        if (lockNumber === 8) {
          gameOver = true;
        } else {
          setLockNumber(lockNumber+1);
        }
        setCookie('lockStatus', newLockStatus, { path: '/' });
        setLockStatus(newLockStatus); 
        setCookie('streak', streak+1, { path: '/' });
        setStreak(streak+1);

        setConfettiActive(true); 
        setDigits(nextLock());
        setGuessKey(uuidv4());
      } else {
        //console.log("Drat! Bad Guess. " + guess + " " + solution);
        setStreak(0);
        setCookie('streak', streak, { path: '/' });
        setGuessKey(uuidv4());
        if (gameOver === true) {
          // The game is over
          let newLockStatus = [];
          for (let i = 0; i < lockStatus.length; i++) {
            if (i < lockNumber) {
              newLockStatus.push(lockStatus[i]);
            } else {
              newLockStatus.push("locked");
            }
          }
          setCookie('lockStatus', newLockStatus, { path: '/' });
          setLockStatus(newLockStatus); 
        }      
      }
      if (gameOver === true) {
        setIsOpenSolvedModal(true);
        setCookie("gameOver", true, { path: '/'});
      }
      setGuess(0);
    }
  };

  function updateGuess(value) {
    setGuess(value);
    setConfettiActive(false);
  }

  return (
    <CookiesProvider>
      <ChakraProvider theme={theme}>
        <Box textAlign="center" fontSize="xl">
          <Grid minH="100vh" p={3}>
            <ColorModeSwitcher justifySelf="flex-end" />
            <VStack spacing={8}>
              <Heading>unlockr (v0.0.1)</Heading>
              <Padlocks lockStatus={ lockStatus } key={ lockStatus.toString() } />
              <VStack spacing={0}>
                <TimeTillReset gameOver={gameOver} gameDate={gameDate} key={uuidv4()} />
                <Text hidden={gameOver}>Turns Remaining: {guesses}</Text>
                <Text>Streak: {streak}</Text>
              </VStack>
              <HStack hidden={gameOver}>
                <PinInput onChange={updateGuess} key={guessKey}>
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
                <Button size="lg" onClick={checkSolution}>Enter</Button>
              </HStack>
              <Button hidden={gameOver} size="lg" onClick={openHelpModal}>How to Play</Button>
              <Button hidden={!gameOver} size="lg" onClick={openSolvedModal}>Today's Results</Button>
              <Confetti active={ confettiActive } config={ confettiConfig }/>
              <Hints gameOver={gameOver} digits={digits} key={digits.toString()} />
            </VStack>
          </Grid>
        </Box>
        <Modal onClose={closeHelpModal} isOpen={isOpenHelpModal} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>unlockr is a logic puzzle game.</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={6} align="left">
                <Text>You have ten guesses to unlock nine three digit combination locks using the hints provided. Each puzzle can be solved using logic, no guessing is required.</Text>
                <Text>Each puzzle has hints that will help you deduce the combination. Click on each digit of the hints to cycle through four colors to help you track your solution. Try it below.</Text>
                <HStack>
                  <Hint name="helphintdigit1" digitValue="1" />
                  <Hint name="helphintdigit2" digitValue="2" />
                  <Hint name="helphintdigit3" digitValue="3" />
                </HStack>
                <Text>When you've solved the puzzle, type your solution into the PIN pad and click Enter. When you've solved all the puzzles or run out of guesses, you can share your results with your friends.</Text>
                <Checkbox isChecked={dontShowHelpOnStart} onChange={toggleShowHelpModal}>Don't show this page again</Checkbox>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button onClick={closeHelpModal}>Let's Play!</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>


<Modal onClose={closeSolvedModal} isOpen={isOpenSolvedModal} isCentered>
<ModalOverlay />
<ModalContent>
  <ModalHeader>Here's how you did on today's unlockr!</ModalHeader>
  <ModalCloseButton />
  <ModalBody>
    <VStack spacing={6} align="left">
      <Text>Your results were:</Text>
      <ResultsHTML streak={streak} lockStatus={ lockStatus } />
      <Text>Click the Share Results button below to copy your results to the clipboard. Feel free to share via text or social media and c hallenge your friends!</Text>
    </VStack>
  </ModalBody>
  <ModalFooter>
    <HStack>
    <ResultsButton lockStatus={lockStatus} streak={streak} />
    <Button onClick={closeSolvedModal}>Close</Button>
    </HStack>
  </ModalFooter>
</ModalContent>
</Modal>

  </ChakraProvider>
    </CookiesProvider>  
);

function readDontShowHelpCookie() {
  if (cookies.dontShowHelp === "true") {
    return true;
  }
  return false;
}
    
function readStreakCookie() {
  if (cookies.streak) {
    return Number(cookies.streak);
  } 
  return 0;
}
  
function readGuessesCookie() {
  if (cookies.guesses) {
    return Number(cookies.guesses);
  }
  return 10;
}

function openHelpModal() {
  setIsOpenHelpModal(true)
};

function closeHelpModal() {
  setIsOpenHelpModal(false)
};

function openSolvedModal() {
  setIsOpenSolvedModal(true);
};

function closeSolvedModal() {
  window.location.reload();
};
};

export default App;
